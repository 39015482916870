import PropTypes from "prop-types";
import { StyledAlert } from "./Alert.style";
import { useFetch } from "../../hooks";

const Alert = ({ title, text }) => {
  const [loading, items] = useFetch(
    "https://www.admin.horlogesdautrefois.fr/api/alerts"
  );

  const item = items.data ? items.data[0]?.attributes.text : "";

  return (
    <StyledAlert.Container>
      {items ? <StyledAlert.Wrapper>{item}</StyledAlert.Wrapper> : null}
    </StyledAlert.Container>
  );
};

// Validation des types des props
Alert.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Alert;
