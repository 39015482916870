import styled, { css } from "styled-components";
import { query } from "../../style/mediaQueries";
import { Link } from "react-router-dom";
import { Burger } from "../";
import { FadeIn } from "../../style/animation";
import StyledButton from "../Button/Button.style";

// Default Style
const defaultStyle = {
  container: css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: #101010;
    padding: 0 1rem;
    z-index: 9998;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  `,
  logo: css`
    width: 50px;
    height: 50px;
    z-index: 9999;
  `,
  links: (isActive) => css`
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 50vw;
    top: 0;
    right: ${isActive ? "0" : "-50vw"};
    padding: 5rem 0;
    justify-content: space-between;
    background-color: #202020;
    transition: ease-in-out 0.3s;
  `,
  button: css`
    padding: 0.2rem;
    border: none;
    font-size: 1rem;
    text-align: center;
  `,
  searchBar: css``,
};

// Media Queries
const queries = {
  tablet: {
    container: query.tablet(css``),
  },
  laptop: {
    container: query.laptop(css``),
    links: query.laptop(css`
      position: relative;
      flex-direction: row;
      justify-content: flex-end;
      height: auto;
      width: 100vw;
      right: 0;
      padding: 0;
      background-color: transparent;
    `),
    button: query.laptop(css`
      border: 1px solid rgba(223, 190, 106, 0.3);
      font-size: 11px;
      text-align: start;
      padding: 0.8rem;
    `),
  },
  desktop: {
    container: query.desktop(css`
      height: 85px;
    `),
    button: query.desktop(css`
      font-size: 1.3rem;
    `),
  },
};

const Container = styled(FadeIn)`
  ${defaultStyle.container}
  ${queries.laptop.container}
  ${queries.desktop.container}
`;

const Logo = styled.img`
  ${defaultStyle.logo}
`;

const ImportedBurger = styled(Burger)``;

const ImportedButton = styled(StyledButton.Link)`
  ${defaultStyle.button}
  ${queries.laptop.button}
  ${queries.desktop.button}
`;

const Links = styled.div`
  ${({ isActive }) => defaultStyle.links(isActive)}
  ${queries.laptop.links}
`;

const ImportedLink = styled(Link)``;

const SearchBar = styled.input``;

const StyledMenu = {
  Container,
  Logo,
  Links,
  Link: ImportedLink,
  Burger: ImportedBurger,
  Button: ImportedButton,
  SearchBar,
};

export { StyledMenu };
