import styled from "styled-components";

const LangagePopUpContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0.5rem;
  right: 0.5rem;
`;

const LangagePopUpFlag = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 0.5rem;
  cursor: pointer;
  &:hover {
    transition: ease-in-out 0.4s;
    transform: scaleY(90%) scaleX(90%);
  }
`;

export { LangagePopUpContainer, LangagePopUpFlag };
