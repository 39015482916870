import styled, { css } from "styled-components";

const GlobalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => css`
    width: ${size + 12}vw;
    height: ${size + 12}vw;
  `}
  border-radius: 50%;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  background-color: transparent;
`;

export { GlobalContainer };
