import styled, { css } from "styled-components";
import { query } from "../../style/mediaQueries";
import { titleColor, titleFont } from "../../style/variables";

// Default Style

const defaultStyle = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    margin: 2rem 0;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
  `,
  title: css`
    font-family: ${titleFont};
    font-size: 1.5rem;
    letter-spacing: 5px;
    margin-bottom: 1rem;
  `,
  description: css`
    color: #fff;
    margin-bottom: 1rem;
    width: 500px;
    line-height: 2rem;
  `,
  form: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #151515;
    border: 1px solid ${titleColor};
    padding: 1rem;
    width: auto;
    margin: 1rem;
  `,
  input: css`
    width: 300px;
    padding: 0.8rem;
  `,
  checkbox: css`
    /* width: 400px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
  `,
  span: css``,
};

const queries = {
  tablet: {
    container: query.tablet(css``),
    title: query.tablet(css``),
    description: query.tablet(css``),
    form: query.tablet(css``),
    input: query.tablet(css``),
    checkbox: query.tablet(css``),
    span: query.tablet(css``),
  },
  laptop: {
    container: query.laptop(css``),
    title: query.laptop(css``),
    description: query.laptop(css``),
    form: query.laptop(css``),
    input: query.laptop(css``),
    checkbox: query.laptop(css``),
    span: query.laptop(css``),
  },
  desktop: {
    container: query.desktop(css``),
    title: query.desktop(css``),
    description: query.desktop(css``),
    form: query.desktop(css``),
    input: query.desktop(css``),
    checkbox: query.desktop(css``),
    span: query.desktop(css``),
  },
};

const Container = styled.div`
  ${defaultStyle.container}
  ${queries.tablet.container}
  ${queries.laptop.container}
  ${queries.desktop.container}
`;

const Title = styled.span`
  ${defaultStyle.title}
  ${queries.tablet.title}
  ${queries.laptop.title}
  ${queries.desktop.title}
`;

const Description = styled.p`
  ${defaultStyle.description}
  ${queries.tablet.description}
  ${queries.laptop.description}
  ${queries.desktop.description}
`;

const Form = styled.form`
  ${defaultStyle.form}
  ${queries.tablet.form}
  ${queries.laptop.form}
  ${queries.desktop.form}
`;

const Input = styled.input`
  ${defaultStyle.input}
  ${queries.tablet.input}
  ${queries.laptop.input}
  ${queries.desktop.input}
`;

const Checkbox = styled.div`
  ${defaultStyle.checkbox}
  ${queries.tablet.checkbox}
  ${queries.laptop.checkbox}
  ${queries.desktop.checkbox}
`;

const Span = styled.span`
  ${defaultStyle.span}
  ${queries.tablet.span}
  ${queries.laptop.span}
  ${queries.desktop.span}
`;

const StyledNewsLetter = {
  Container,
  Title,
  Description,
  Form,
  Input,
  Checkbox,
  Span,
};

export { StyledNewsLetter };

const NewsLetterContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 2rem 0;
  @media screen and (max-width: 768px) {
    width: inherit;
  }
`;

const NewsLetterTitle = styled.span`
  font-family: ${titleFont};
  color: #fff;
  font-size: 3rem;
  letter-spacing: 5px;
`;

const NewsLetterDescription = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  width: 550px;
  @media screen and (max-width: 768px) {
    width: 400px;
  }
`;

const NewsLetterForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #151515;
  justify-content: space-around;
  width: 900px;
  height: 350px;
  border: 1px solid ${titleColor};
  padding: 1rem;
  @media screen and (max-width: 768px) {
    width: 400px;
  }
`;

const NewsLetterInput = styled.input`
  width: 500px;
  padding: 0.8rem;
  @media screen and (max-width: 768px) {
    width: 300px;
  }
`;

const NewsLetterCheckbox = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NewsLetterSpan = styled.span`
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 1rem;
`;
