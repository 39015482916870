import Article from "./Article/Article";
import Announcement from "./Announcement/Announcement";
import Contact from "./Contact/Contact";
import Discovery from "./Discovery/Discovery";
import Elec from "./Elec/Elec";
import Meca from "./Meca/Meca";
import WorkShop from "./WorkShop/WorkShop";
import Home from "./Home/Home";
import Privacy from "./Privacy/Privacy";

const Page = {
  Article,
  Announcement,
  Contact,
  Discovery,
  Elec,
  Meca,
  WorkShop,
  Home,
  Privacy,
};

export default Page;
