import styled from "styled-components";
import Cadran from "../../assets/img/cadran.png";

const GlobalStyle = () => `
    position: absolute;
    height: 130px;
    width: 6px;
    margin: auto;
    top: -22%;
    left: 0;
    bottom: 0;
    right: 0;
    transform-origin: bottom center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
    background: #606060;
    z-index: 2;
`;
const ClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: url(${Cadran}) no-repeat center;
  background-size: 88%;
  width: 600px;
  height: 600px;
  background-color: #101010cf;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 350px;
    height: 350px;
    margin: 1.5rem;
  }
`;
const Hour = styled.span`
  ${GlobalStyle}
  transform: ${({ hour }) => `rotate(${hour}deg)`};
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Minute = styled.span`
  ${GlobalStyle}
  position: absolute;
  height: 190px;
  width: 4px;
  top: -31.5%;
  left: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  transform: ${({ minute }) => `rotate(${minute}deg)`};
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Second = styled.span`
  position: absolute;
  height: 260px;
  width: 4px;
  margin: auto;
  top: -43%;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 4px;
  background: #606060;
  transform-origin: bottom center;
  transform: ${({ second }) => `rotate(${second}deg)`};
  z-index: 2;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Dot = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #606060;
  border: 2px solid #101010;
  border-radius: 50%;
  margin: auto;
  z-index: 2;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export { ClockContainer, Hour, Minute, Second, Dot };
