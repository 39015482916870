import styled, { css } from "styled-components";

// Default Style

const defaultStyle = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0.5rem;
  `,
  wrapper: css`
    display: flex;
    justify-content: "center";
    width: 100%;
    padding: 0.5rem;
  `,
  select: css``,
  option: css``,
  title: css`
    font-family: arial;
    color: #fff;
    margin-right: 0.5rem;
  `,
};

const Container = styled.div`
  ${defaultStyle.container}
`;

const Wrapper = styled.div`
  ${defaultStyle.wrapper}
`;

const Select = styled.select`
  ${defaultStyle.select}
`;

const Option = styled.option`
  ${defaultStyle.option}
`;

const Title = styled.span`
  ${defaultStyle.title}
`;

const StyledSort = {
  Container,
  Wrapper,
  Select,
  Option,
  Title,
};

export { StyledSort };
