import { useState } from "react";
import { useForm, useFileUpload, useEditor } from "../../../hooks";
import { ContentEditor, ContentToolBar, Inputs } from "..";
import { inputs } from "../../../configs/input.conf";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import "./Form.scss";

const Form = () => {
  const [notification, setNotification] = useState({ message: "", type: "" });
  const { form, setForm, inputAction } = useForm();
  // const { files, setFiles, fileAction } = useFileUpload();
  const [loader, setLoader] = useState(false);
  const {
    editorState,
    handleEditorStateChange,
    selector,
    blockStyleFn,
    purgeEditor,
  } = useEditor();

  // Convertir l'EditorState en HTML
  const convertEditorStateToHTML = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    return htmlContent;
  };
  const contentState = editorState.getCurrentContent();

  const handleSubmit = () => {
    setLoader(true);
    // // Taille maximale par fichier (exemple: 5 Mo)
    // const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 Mo
    // // Types d'images autorisés
    // const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"];
    // // Nombre maximum de fichiers
    // const MAX_FILES = 3;
    // Regex pour valider l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const popUp = (load, message, type) => {
      setLoader(load);
      setNotification({
        message: message,
        type: type,
      });
      // Masquer la notification après 3 secondes
      setTimeout(() => {
        setNotification({ message: "", type: "" });
      }, 3000);
    };

    // Vérification de l'email
    if (!form.name) {
      popUp(false, "Nom manquant", "error");
      return;
    }

    // Vérification de l'email
    if (!emailRegex.test(form.email)) {
      popUp(false, "Adresse email invalide", "error");
      return;
    }

    // // Vérification de l'email
    // if (!form.subject) {
    //   popUp(false, "Objet manquant", "error");
    //   return;
    // }

    // Vérification de l'email
    if (!contentState.hasText()) {
      popUp(false, "Message manquant", "error");
      return;
    }

    // // Vérification du nombre de fichiers
    // if (files.length > MAX_FILES) {
    //   popUp(
    //     false,
    //     `Le nombre maximum de fichiers autorisés est de ${MAX_FILES}`,
    //     "error"
    //   );
    //   return;
    // }

    // // Validation des fichiers
    // for (let file of files) {
    //   // Vérifier le type de fichier
    //   if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
    //     popUp(
    //       false,
    //       `Le fichier ${file.name} n'est pas une image valide`,
    //       "error"
    //     );
    //     return;
    //   }

    //   // Vérifier la taille du fichier
    //   if (file.size > MAX_FILE_SIZE) {
    //     alert(
    //       `Le fichier ${file.name} dépasse la taille maximale autorisée de 5 Mo`
    //     );
    //     return;
    //   }
    // }

    // Conversion de l'éditeur en HTML
    const editorToHtml = convertEditorStateToHTML(editorState);

    // Création de l'objet FormData à envoyer
    const formData = new FormData();
    formData.append("name", form.name); // Ajoute les données de formulaire
    formData.append("email", form.email); // Ajoute les données de formulaire
    // formData.append("subject", form.subject); // Ajoute les données de formulaire
    formData.append("editorToHtml", editorToHtml); // Ajoute l'éditeur HTML

    // // Ajoute chaque fichier au FormData
    // files.forEach((file) => {
    //   formData.append("files", file);
    // });
    // Envoyer les données au backend avec Axios
    axios
      .post("https://www.admin.horlogesdautrefois.fr/api/email", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Utilise multipart/form-data pour les fichiers
        },
      })
      .then((response) => {
        setLoader(false);
        // Afficher une notification de succès
        setNotification({
          message: "Message envoyé avec succès !",
          type: "success",
        });

        // Masquer la notification après 3 secondes
        setTimeout(() => {
          setNotification({ message: "", type: "" });
          setForm({ name: "", email: "" });
          // setFiles([]);
          purgeEditor();
        }, 3000);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // Afficher une notification d'erreur
        setNotification({
          message: "Une erreur est survenue. Réessayez.",
          type: "error",
        });

        // Masquer la notification après 3 secondes
        setTimeout(() => {
          setNotification({ message: "", type: "" });
        }, 3000);
      });
  };

  return (
    <div className="form__global-container">
      <div className="form__banner">
        <p>Me Contacter</p>
      </div>
      <Inputs inputs={inputs} inputChange={inputAction.change} form={form} />
      <ContentEditor
        editorState={editorState}
        handleEditorStateChange={handleEditorStateChange}
        blockStyleFn={blockStyleFn}
      />
      <ContentToolBar
        // files={files}
        // fileAction={fileAction}
        selector={selector}
        handleSubmit={handleSubmit}
        notification={notification}
        loader={loader}
      />
    </div>
  );
};

export default Form;
