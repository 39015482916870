import { currentLangage } from "../currentLangage";

const elec = {
  fr: {
    title: "Horlogerie électrique",
    description:
      "Née de la découverte de l’électromagnétisme au début du XIX ème siècle, l’horlogerie électrique allait révolutionner la mesure du temps et se développer sous de multiples formes jusqu’à l’avènement de l'horloge à quartz.",
  },
  en: {
    title: "Electric horology",
    description:
      "Born from the discovery of electromagnetism at the beginning of the 19th century, electric clocks would revolutionize the measurement of time and develop in many forms until the advent of the quartz clock.",
  },
  de: {
    title: "Elektrische Uhren",
    description:
      "Aus der Entdeckung des Elektromagnetismus zu Beginn des 19. Jahrhunderts entstanden, revolutionierten elektrische Uhren die Zeitmessung und entwickelten sich bis zum Aufkommen der Quarzuhr in vielen Formen.",
  },
};

const elecTrad = elec[currentLangage];

export default elecTrad;
