import { useState, useEffect } from "react";

const useMobileSize = () => {
  const [mobileSize, setMobileSize] = useState(false);

  useEffect(() => {
    function handleResize() {
      setMobileSize(window.innerWidth < 768);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return { mobileSize };
};

export default useMobileSize;
