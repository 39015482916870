import { Routes, Route } from "react-router-dom";
import Page from "../pages";
import { FadeIn } from "../style/animation";

const AllRoutes = () => {
  return (
    <FadeIn>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Page.Discovery />} />
        <Route path="/home" element={<Page.Home />} />
        <Route path="/elec" element={<Page.Elec />} />
        <Route path="/meca" element={<Page.Meca />} />
        <Route path="/workshop" element={<Page.WorkShop />} />
        <Route path="/article/:id" element={<Page.Article />} />
        <Route path="/announcement" element={<Page.Announcement />} />
        <Route path="/contact" element={<Page.Contact />} />
        <Route path="/privacy" element={<Page.Privacy />} />
      </Routes>
    </FadeIn>
  );
};

export default AllRoutes;
