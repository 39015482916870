import {useState, useEffect} from "react";
import axios from "axios";

const useFetch = (url) => {
  const [state, setState] = useState({
    items: [],
    loading: true,
  });

  useEffect(() => {
    const data = async () => {
      const res = await axios.get(url);
      if (res) {
        setState({
          items: res.data,
          loading: false,
        });
      } else {
        setState({
          items: [],
          loading: false,
        });
      }
    };
    data();
  }, [url]);

  return [state.loading, state.items];
};

export default useFetch;
