import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components";
import "./Article.scss";

const Article = () => {
  const navigate = useNavigate();
  const id = window.location.pathname.split("/").splice(2, 2).join();
  const [loading, items] = useFetch(
    `https://www.admin.horlogesdautrefois.fr/api/articles/id/${id}`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <Loading>Chargement de l'article</Loading>;
  }

  return items ? (
    <>
      <Helmet>
        <title>{items[0].title}</title>
        <meta name="description" content={items[0].title} />
        <link rel="canonical" href={`/article/${items[0].id}`} />
      </Helmet>
      <div className="article__global-container">
        <div className="article__wrapper">
          <h1>{items[0].title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: items[0].content,
            }}
            className="article__content"
          ></div>
          <button className="article__button" onClick={() => navigate(-1)}>
            Retour
          </button>
        </div>
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default Article;
