import AllRoutes from "./routes/AllRoutes";
import { Footer, Menu } from "./components";
import { menuConf } from "./configs/menuConf";

const App = () => {
  return (
    <div className="App">
      <Menu type="main" conf={menuConf} />
      <AllRoutes />
      <Footer />
    </div>
  );
};

export default App;
