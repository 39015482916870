import PropTypes from "prop-types";
import { Banner, Masonry, Sort } from "../";
import { TemplateContainer } from "./Template.style";
import { useState } from "react";
import { useMobileSize } from "../../hooks";

const Template = ({ bannerConf, menuConf, category }) => {
  const { mobileSize } = useMobileSize();
  const [sort, setSort] = useState(
    `https://www.admin.horlogesdautrefois.fr/api/articles/category/${category}`
  );
  return (
    <TemplateContainer>
      <Banner
        title={bannerConf.title}
        subtitle={bannerConf.subtitle}
        description={bannerConf.description}
      />
      <Sort
        conf={menuConf}
        mobileSize={mobileSize}
        category={category}
        setSort={setSort}
      />
      <Masonry url={sort} />
    </TemplateContainer>
  );
};

// Validation des types des props
Template.propTypes = {
  bannerConf: PropTypes.object.isRequired,
  menuConf: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
};

export default Template;
