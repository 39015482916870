import { traduction } from "../utils/translate/components/menu";
const { menuTrad, mecaTrad, elecTrad, workshopTrad } = traduction;

const menuConf = [
  { name: menuTrad.home, to: "/home", type: "link" },
  { name: menuTrad.meca, to: "/meca", type: "link" },
  { name: menuTrad.elec, to: "/elec", type: "link" },
  {
    name: menuTrad.workshop,
    to: "/workshop",
    type: "link",
  },
  {
    name: menuTrad.announcement,
    to: "/announcement",
    type: "link",
  },
  {
    name: menuTrad.contact,
    to: "/contact",
    type: "link",
  },
];

const mecaMenuConf = [
  {
    name: mecaTrad.allArticle,
    to: "api/articles/category/mecanique",
    type: "button",
  },
  {
    name: mecaTrad.lanterne,
    to: "api/articles/mecanique/lanterne",
    type: "button",
  },
  {
    name: mecaTrad.comtoise,
    to: "api/articles/mecanique/comtoise",
    type: "button",
  },
  {
    name: mecaTrad.stNicolas,
    to: "api/articles/mecanique/stnicolas",
    type: "button",
  },
  {
    name: mecaTrad.blackForest,
    to: "api/articles/mecanique/foretnoire",
    type: "button",
  },
  {
    name: mecaTrad.variousMovement,
    to: "api/articles/mecanique/divers",
    type: "button",
  },
];

const elecMenuConf = [
  {
    name: elecTrad.allArticle,
    to: "api/articles/category/electrique",
    type: "button",
  },
  {
    name: elecTrad.historic,
    to: "api/articles/id/5",
    type: "button",
  },
  {
    name: elecTrad.electromagnetism,
    to: "api/articles/id/5",
    type: "button",
  },
  {
    name: elecTrad.ato,
    to: "api/articles/electrique/ato",
    type: "button",
  },
  {
    name: elecTrad.brillie,
    to: "api/articles/electrique/brillie",
    type: "button",
  },
  {
    name: elecTrad.bulleClock,
    to: "api/articles/electrique/bulleclock",
    type: "button",
  },
];

const workShopMenuConf = [
  {
    name: workshopTrad.allArticle,
    to: "api/articles/category/atelier",
    type: "button",
  },
  {
    name: workshopTrad.tool,
    to: "api/articles/atelier/outils",
    type: "button",
  },
  {
    name: workshopTrad.restoration,
    to: "api/articles/atelier/restauration",
    type: "button",
  },
  {
    name: workshopTrad.plug,
    to: "api/articles/atelier/bouchonner",
    type: "button",
  },
  {
    name: workshopTrad.pivot,
    to: "api/articles/atelier/pivot",
    type: "button",
  },
  {
    name: workshopTrad.toClean,
    to: "api/articles/atelier/nettoyer",
    type: "button",
  },
];

const annonceMenuConf = [
  {
    name: "Remerciements",
    to: "api/articles/id/46",
    type: "link",
  },
  {
    name: "Évènements",
    to: "api/articles/annonce/evenement",
    type: "button",
  },
  {
    name: "Matériels",
    to: "api/articles/annonce/materiel",
    type: "button",
  },
];

export {
  menuConf,
  mecaMenuConf,
  elecMenuConf,
  workShopMenuConf,
  annonceMenuConf,
};
