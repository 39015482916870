import styled, { css } from "styled-components";
import { query } from "../../style/mediaQueries";

// Default Style
const defaultStyle = {
  container: css`
    display: flex;
    flex-direction: column;
    width: auto;
    color: #fff;
    background-color: #212121;
    margin: 1rem;
    font-family: arial;
    position: relative;
  `,
  wrapper: css`
    padding: 0.8rem;
  `,
  image: css`
    max-width: 100%;
    object-fit: cover;
    cursor: pointer;
  `,
  title: css`
    font-size: 2rem;
  `,
  descriptionContainer: css`
    overflow: hidden;
    flex-grow: 1;
    margin-top: 2rem;
  `,
  description: css`
    line-height: 1.8rem;
    display: -webkit-box;
    height: inherit;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
};

// Media Queries
const queries = {
  laptop: {
    container: query.laptop(css`
      flex-direction: row;
      width: auto;
    `),
    image: query.laptop(css`
      max-width: 220px;
    `),
  },
  laptopL: {
    image: query.laptop(css`
      max-width: 300px;
    `),
  },
};

const Container = styled.div`
  ${defaultStyle.container}
  ${queries.laptop.container}
`;

const Wrapper = styled.div`
  ${defaultStyle.wrapper}
  ${queries.laptop.wrapper}
`;

const Image = styled.img`
  ${defaultStyle.image}
  ${queries.laptop.image}
  ${queries.laptopL.image}
`;

const Title = styled.span`
  ${defaultStyle.title}
  ${queries.laptop.title}
`;

const DescriptionContainer = styled.div`
  ${defaultStyle.descriptionContainer}
  ${queries.laptop.descriptionContainer}
`;

const Description = styled.p`
  ${defaultStyle.description}
  ${queries.laptop.description}
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
`;

const StyledCard = {
  Container,
  Wrapper,
  Image,
  Title,
  DescriptionContainer,
  Description,
  ButtonContainer,
};

export { StyledCard };
