import styled from "styled-components";
import { SideFade } from "../../style/animation";

const PitchContainer = styled(SideFade)`
  width: 45vw;
  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;
const Title = styled.h2`
  font-family: Helvetica, sans-serif;
  letter-spacing: 4px;
  color: #fff;
  margin-bottom: 1rem;
`;
const Desc = styled.span`
  color: #fff;
  line-height: 1.5rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-bottom: 1rem;
  background-color: #101010cf;
`;
const Sign = styled.span`
  color: #a0884f;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
`;

export { PitchContainer, Wrapper, Title, Desc, Sign };
