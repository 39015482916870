import { useState, useEffect } from "react";

const useClock = () => {
  const [date, setDate] = useState(new Date());

  const refreshClock = () => {
    setDate(new Date());
  };

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  let hourRotation = 30 * hour + 0.5 * minute;
  let minuteRotation = 6 * minute;
  let secondRotation = 6 * second;

  return {
    hourRotation,
    minuteRotation,
    secondRotation,
  };
};

export default useClock;
