import {
  PageBackground,
  AnimationWrapper,
  Particle1,
  Particle2,
  Particle3,
  Particle4,
} from "./Particles.style";

const Particles = () => {
  return (
    <>
      <PageBackground />
      <AnimationWrapper>
        <Particle1 />
        <Particle2 />
        <Particle3 />
        <Particle4 />
      </AnimationWrapper>
    </>
  );
};

export default Particles;
