import {
  BurgerContainer,
  BurgerBar1,
  BurgerBar2,
  BurgerBar3,
} from "./Burger.style";

const Burger = ({ type, isActive, setIsActive }) => {
  return (
    <BurgerContainer
      type={type}
      onClick={() => setIsActive(!isActive)}
      isActive={isActive}
    >
      <BurgerBar1 />
      <BurgerBar2 />
      <BurgerBar3 />
    </BurgerContainer>
  );
};

export default Burger;
