import {
  RichUtils,
  Modifier,
  EditorState,
  DefaultDraftBlockRenderMap,
  AtomicBlockUtils,
} from "draft-js";
import { useState } from "react";
import { Map } from "immutable";

const useEditor = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const purgeEditor = () => {
    setEditorState(EditorState.createEmpty());
  };

  const setBlockData = (data) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Applique les données au bloc sélectionné
    const newContentState = Modifier.setBlockData(
      contentState,
      selection,
      data
    );
    setEditorState(
      EditorState.push(editorState, newContentState, "change-block-data")
    );
  };

  const handleAlignmentChange = (alignment) => {
    setBlockData({ textAlign: alignment });
  };

  // Gestion de l'historique de l'éditeur dans l'état
  const [undoStack, setUndoStack] = useState([]);
  const [undoIndex, setUndoIndex] = useState(-1);

  const toggleInlineStyle = (style) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, style);
    handleEditorStateChange(newEditorState);
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleEditorStateChange = (newState) => {
    // Mettez à jour l'historique
    if (undoIndex < undoStack.length - 1) {
      setUndoStack((prev) => [...prev.slice(0, undoIndex + 1), newState]);
    } else {
      setUndoStack((prev) => [...prev, newState]);
    }
    setUndoIndex((prev) => prev + 1);
    setEditorState(newState);
  };

  // Fonction pour annuler la dernière modification
  const handleUndo = () => {
    const newState = EditorState.undo(editorState);
    setEditorState(newState);
  };

  // Fonction pour rétablir une modification annulée
  const handleRedo = () => {
    const newState = EditorState.redo(editorState);
    setEditorState(newState);
  };

  const handleRemoveFormatting = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const styles = editorState.getCurrentInlineStyle();

      // Supprimer tous les styles appliqués
      let newContentState = styles.reduce((content, style) => {
        return Modifier.removeInlineStyle(content, selection, style);
      }, contentState);

      // Mettre à jour l'état de l'éditeur
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "change-inline-style"
      );
      setEditorState(newEditorState);
    }
  };

  const adjustIndentation = (increase = true) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);

    // Récupérer la profondeur actuelle du bloc (entre 0 et 4)
    const depth = block.getDepth();
    const newDepth = increase ? Math.min(depth + 1, 4) : Math.max(depth - 1, 0);

    // Créer un nouveau bloc avec la nouvelle profondeur
    const newBlock = block.set("depth", newDepth);

    // Mettre à jour le ContentState avec le bloc modifié
    const newContentState = contentState.merge({
      blockMap: contentState.getBlockMap().set(blockKey, newBlock),
    });

    // Créer un nouvel état de l'éditeur avec le contenu mis à jour
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "adjust-depth"
    );

    handleEditorStateChange(newEditorState);
  };

  const blockStyleFn = (block) => {
    let alignment = "left";
    block.findStyleRanges((e) => {
      if (e.hasStyle("center")) {
        alignment = "center";
      }
      if (e.hasStyle("right")) {
        alignment = "right";
      }
    });
    return `editor-alignment-${alignment}`;
  };

  const alignmentStyles = ["left", "right", "center"];

  const applyAlignment = (newStyle) => {
    let styleForRemove = alignmentStyles.filter((style) => style !== newStyle);
    let currentContent = editorState.getCurrentContent();
    let selection = editorState.getSelection();
    let focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
    let anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
    let isBackward = selection.getIsBackward();

    let selectionMerge = {
      anchorOffset: 0,
      focusOffset: focusBlock.getLength(),
    };

    if (isBackward) {
      selectionMerge.anchorOffset = anchorBlock.getLength();
    }
    let finalSelection = selection.merge(selectionMerge);
    let finalContent = styleForRemove.reduce(
      (content, style) =>
        Modifier.removeInlineStyle(content, finalSelection, style),
      currentContent
    );
    let modifiedContent = Modifier.applyInlineStyle(
      finalContent,
      finalSelection,
      newStyle
    );
    const nextEditorState = EditorState.push(
      editorState,
      modifiedContent,
      "change-inline-style"
    );
    setEditorState(nextEditorState);
  };

  const increaseIndent = () => {
    adjustIndentation(true); // Augmenter l'indentation
  };

  const decreaseIndent = () => {
    adjustIndentation(false); // Diminuer l'indentation
  };

  const blockRenderMap = Map({
    left: {
      element: "div",
      wrapper: <div className="text-left" />,
    },
    center: {
      element: "div",
      wrapper: <div className="text-center" />,
    },
    right: {
      element: "div",
      wrapper: <div className="text-right" />,
    },
  });

  // Fonction pour gérer l'insertion de l'image
  const addImage = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const url = e.target.result;

      // Insertion de l'image dans l'éditeur
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "IMAGE",
        "IMMUTABLE",
        { src: url }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        " "
      );

      setEditorState(
        EditorState.forceSelection(
          newEditorState,
          newEditorState.getSelection()
        )
      );
    };
    reader.readAsDataURL(file);
  };

  // Fonction de gestion du téléchargement de l'image
  const handleFileInput = (event) => {
    if (!event || !event.target || !event.target.files) {
      console.error("No file input detected");
      return;
    }

    const file = event.target.files[0];
    if (file) {
      addImage(file);
    }
  };
  // Étendez la map de rendus de blocs par défaut
  const extendedBlockRenderMap =
    DefaultDraftBlockRenderMap.merge(blockRenderMap);

  const selector = (e, type, action) => {
    e.preventDefault();
    switch (type) {
      case "inline":
        toggleInlineStyle(action);
        break;
      case "block":
        toggleBlockType(action);
        break;
      case "undo":
        handleUndo();
        break;
      case "redo":
        handleRedo();
        break;
      case "remove":
        handleRemoveFormatting();
        break;
      case "increaseIndent":
        increaseIndent();
        break;
      case "decreaseIndent":
        decreaseIndent();
        break;
      case "alignment":
        applyAlignment(action);
        break;
      case "strike":
        toggleInlineStyle("STRIKETHROUGH");
        break;
      case "image":
        handleFileInput();
        break;
      default:
        break;
    }
  };

  return {
    editorState,
    setEditorState,
    handleEditorStateChange,
    selector,
    extendedBlockRenderMap,
    blockStyleFn,
    purgeEditor,
  };
};

export default useEditor;
