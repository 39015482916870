import { Helmet } from "react-helmet-async";
import { Template } from "../../components";
import { elecTrad } from "../../utils/translate";
import { elecMenuConf } from "../../configs/menuConf";
import { FadeIn } from "../../style/animation";

const Elec = () => {
  return (
    <>
      <Helmet>
        <title>Horloges d'autrefois - Électrique</title>
        <meta
          name="description"
          content="Née de la découverte de l’électromagnétisme au début du XIX ème siècle, l’horlogerie électrique allait révolutionner la mesure du temps"
        />
        <link rel="canonical" href="/electric" />
      </Helmet>
      <FadeIn>
        <Template
          bannerConf={elecTrad}
          menuConf={elecMenuConf}
          category={"electrique"}
        />
      </FadeIn>
    </>
  );
};

export default Elec;
