import { currentLangage } from "../currentLangage";

const input = {
  fr: {
    username: "Nom d'utilisateur",
    email: "Adresse e-mail",
    password: "Mot de passe",
    confirmPassword: "Confirmer le mot de passe",
    update: "Modifier",
    subject: "Sujet",
    message: "Message",
  },
  // en: {
  //   username: "Username",
  //   email: "E-mail address",
  //   password: "Password",
  //   confirmPassword: "Confirm password",
  //   update: "Update",
  //   subject: "Subject",
  //   message: "Message",
  // },
  // de: {
  //   username: "Nutzername",
  //   email: "E-Mail Addresse",
  //   password: "Passwort",
  //   confirmPassword: "Bestätige das Passwort",
  //   update: "Bearbeiten",
  //   subject: "Thema",
  //   message: "Nachricht",
  // },
};

const inputs = {
  username: input.fr.username,
  subject: input.fr.subject,
  email: input.fr.email,
  password: input.fr.password,
  confirmPassword: input.fr.confirmPassword,
  submit: input.fr.update,
  message: input.fr.message,
};

export default inputs;
