import { currentLangage } from "../currentLangage";

const discovery = {
  fr: {
    title: "FAISONS CONNAISSANCE…",
    description:
      "Nombreux sont les amateurs d’horlogerie à chercher de l’aide, des contacts, des adresses, de l’information… Ce site est destiné à ceux qui se passionnent pour les horloges lanternes, les Comtoises, les Saint Nicolas, et toutes ces merveilles qui ont égrené les heures d’autrefois. Les néophytes trouveront ici des descriptifs leur permettant de s’orienter, ainsi que des informations pratiques, techniques et historiques… Les connaisseurs pourront comparer leurs propres observations et les communiquer en enrichissant ainsi la communauté horlogère de leur expérience. Vous trouverez ici une plateforme d’échange dénuée de mercantilisme dont le seul but est de recueillir des textes oubliés ou des photos d’horloges remarquables, afin de constituer une base de données comparative. Je remercie par avance les participants qui apporteront leur savoir ou de la documentation quelqu’en soit la forme; ce site sera ce que nous en ferons ensemble !",
    button: "Accéder au site",
  },
  en: {
    title: "LET'S GET TO KNOW EACH OTHER...",
    description:
      "Many watch enthusiasts are looking for help, contacts, addresses, information... This site is intended for those who are passionate about lantern clocks, Comtoises, Saint Nicolas, and all these marvels who ticked off the hours of yesteryear. Neophytes will find here descriptions allowing them to orient themselves, as well as practical, technical and historical information... Connoisseurs will be able to compare their own observations and communicate them, thus enriching the watchmaking community with their experience. Here you will find an exchange platform devoid of commercialism whose sole purpose is to collect forgotten texts or photos of remarkable clocks, in order to constitute a comparative database. I thank in advance the participants who will bring their knowledge or documentation whatever the form; this site will be what we make of it together !",

    button: "Access to the site",
  },
  de: {
    title: "LASSEN SIE UNS EINANDER KENNENLERNEN...",
    description:
      "Viele Uhrenliebhaber suchen nach Hilfe, Kontakten, Adressen, Informationen... Diese Seite ist für diejenigen gedacht, die sich für Laternenuhren, Comtoises, Saint Nicolas und all diese Wunderwerke, die die Stunden vergangener Zeiten abhakten, begeistern. Neulinge finden hier Orientierungsbeschreibungen, praktische, technische und historische Informationen... Kenner können ihre eigenen Beobachtungen vergleichen und kommunizieren und so die Uhrmachergemeinde mit ihren Erfahrungen bereichern. Hier finden Sie eine Austauschplattform ohne Kommerz, deren einziger Zweck darin besteht, vergessene Texte oder Fotos von bemerkenswerten Uhren zu sammeln, um eine vergleichende Datenbank aufzubauen. Ich danke im Voraus den Teilnehmern, die ihr Wissen oder ihre Dokumentation in welcher Form auch immer mitbringen werden; Diese Seite wird das sein, was wir gemeinsam daraus machen!",

    button: "Zugriff auf die Website",
  },
};

const discoveryTrad = discovery[currentLangage];

export default discoveryTrad;
