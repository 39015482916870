import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatClearIcon from "@mui/icons-material/FormatClear";

const type = {
  0: "inline",
  1: "block",
  2: "color",
  3: "redo",
  4: "undo",
  5: "strike",
  7: "remove",
  8: "increaseIndent",
  9: "decreaseIndent",
  10: "alignment",
};

export const tools = [
  { Icon: UndoIcon, title: "Annuler", type: type[4], action: "undo" },
  { Icon: RedoIcon, title: "Rétablir", type: type[3], action: "redo" },
  { Icon: FormatBoldIcon, title: "Gras", type: type[0], action: "BOLD" },
  {
    Icon: FormatItalicIcon,
    title: "Italique",
    type: type[0],
    action: "ITALIC",
  },
  {
    Icon: FormatUnderlinedIcon,
    title: "Souligner",
    type: type[0],
    action: "UNDERLINE",
  },
  {
    Icon: FormatAlignLeftIcon,
    title: "Aligner à gauche",
    type: type[10],
    action: "left",
  },
  {
    Icon: FormatAlignCenterIcon,
    title: "Centrer",
    type: type[10],
    action: "center",
  },
  {
    Icon: FormatAlignRightIcon,
    title: "Aligner à droite",
    type: type[10],
    action: "right",
  },
  {
    Icon: FormatListNumberedIcon,
    title: "Liste numérotée",
    type: type[1],
    action: "ordered-list-item",
  },
  {
    Icon: FormatListBulletedIcon,
    title: "Liste à puces",
    type: type[1],
    action: "unordered-list-item",
  },
  {
    Icon: FormatIndentDecreaseIcon,
    title: "Diminuer le retrait",
    type: type[9],
    action: "decrease-indent",
  },
  {
    Icon: FormatIndentIncreaseIcon,
    title: "Augmenter le retrait",
    type: type[8],
    action: "increase-indent",
  },
  {
    Icon: FormatQuoteIcon,
    title: "Citation",
    type: type[1],
    action: "blockquote",
  },
  {
    Icon: StrikethroughSIcon,
    title: "Barré",
    type: type[5],
    action: "STRIKETHROUGH",
  },
  {
    Icon: FormatClearIcon,
    title: "Supprimer la mise en forme",
    type: type[7],
    action: "REMOVE",
  },
];
