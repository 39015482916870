import styled, { css } from "styled-components";

const InnerCircle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => css`
    width: ${size + 1}vw;
    height: ${size + 1}vw;
  `}
  /* border: 1px solid #000; */
  background-color: transparent;
  border-radius: 100%;
  transform: rotate(-2deg);
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ size }) => css`
    width: ${size}vw;
    height: ${size}vw;
  `}
  background-size: cover;
  border-radius: 50%;
  position: relative;
  /* background-image: ${({ backgroundImage }) =>
    css`url(${backgroundImage})`}; */
`;

export { InnerCircle, Container };
