import StyledFooter from "./Footer.style";
import { useDisplayComponent } from "../../hooks";
import { useFetch } from "../../hooks";
import { Link } from "react-router-dom";

const Footer = () => {
  const [loading, items] = useFetch(
    `https://www.admin.horlogesdautrefois.fr/api/links`
  );
  const { isDisplay } = useDisplayComponent();
  return (
    isDisplay && (
      <StyledFooter.Container>
        <StyledFooter.Title>Liens</StyledFooter.Title>
        <StyledFooter.Links>
          {items?.data?.map((item) => {
            return (
              <div key={Math.random(5 * 8888)}>
                <StyledFooter.Link target="_blank" to={item.attributes.url}>
                  {item.attributes.title}
                </StyledFooter.Link>
              </div>
            );
          })}
        </StyledFooter.Links>
        <StyledFooter.Privacy>
          <p>
            ©Copyright 2024 Horloges D'autrefois. Tout droit réservé.
            <Link to="/privacy">Mentions légales</Link>
          </p>
        </StyledFooter.Privacy>
      </StyledFooter.Container>
    )
  );
};

export default Footer;
