import PropTypes from "prop-types";
import { useBackground } from "../../hooks";
import { StyledBanner } from "./Banner.style";
import { Title } from "../";
import { useLocation } from "react-router-dom";

const Banner = ({ title, subtitle, description }) => {
  const background = useBackground();
  const { pathname } = useLocation();
  return (
    <StyledBanner.Container background={background} path={pathname}>
      <StyledBanner.Wrapper>
        <Title>{title}</Title>
        <StyledBanner.SubTitle>{subtitle}</StyledBanner.SubTitle>
        <StyledBanner.Description>{description}</StyledBanner.Description>
      </StyledBanner.Wrapper>
    </StyledBanner.Container>
  );
};

// Validation des types des props
Banner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
};

export default Banner;
