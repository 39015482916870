import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.footer`
  background-color: #212121;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    color: #fff;
  }
`;

const Title = styled.span`
  margin-top: 1rem;
  margin-bottom: 4rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
`;

const ImportedLink = styled(Link)`
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #fff;
  margin: 1rem;
  transition: ease-in-out 0.3s;
  &:hover {
    color: rgba(223, 190, 106);
  }
`;

const Privacy = styled.div`
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  margin: 2rem 0rem;
  display: flex;

  a {
    margin-left: 1rem;
    text-decoration: none;
    color: rgba(223, 190, 106);
  }
`;

const StyledFooter = {
  Container,
  Title,
  Links,
  Link: ImportedLink,
  Privacy,
};

export default StyledFooter;
