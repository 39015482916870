import { currentLangage } from "../currentLangage";

const meca = {
  fr: {
    title: "Horlogerie mécanique",
    subtitle: "Quand la mesure du temps devînt mécanique …",
    description:
      "Vers la fin du XIIIe siècle, les premières horloges mécaniques destinées à marquer les heures canoniales des communautés religieuses font leur apparition. Tout au long du XIVe siècle, des horloges monumentales dotées de sonneries puissantes sont progressivement installées dans les beffrois et les clochers de quelques grandes villes afin d'y rythmer la vie de leurs habitants... Un peu plus tard, ces horloges se déclineront dans des proportions plus réduites pour habiter de riches intérieurs; l’horloge gothique était née… Puis, au milieu du XVe siècle, l’arrivée du barillet à ressort autorise leur mobilité et entraine une diminution de leur taille pour évoluer vers l’horloge de table . Avec l’arrivée du XVIe siècle leur volume se limitera encore pour aboutir à la montre en une ultime réduction . Ces améliorations successives se traduiront par une importante diversification de la production horlogère qui donnera naissance à l’horloge lanterne puis à la comtoise à la fin du XVIIe siècle.",
  },
  en: {
    title: "Mechanical horology",
    subtitle: "When the measurement of time became mechanical…",
    description:
      "Towards the end of the 13th century, rare mechanical clocks, intended to mark the canonical hours of religious communities, appeared. Throughout the 14th century, these monumental clocks, now equipped with powerful chimes, were installed in belfries and bell towers, thus punctuating the life of certain large European cities. Then, to inhabit the rich interiors, they will come in the form of clocks of miniature construction, like them, in iron, weight and foliot, the Gothic clock was born... In the middle of the 15th century, a new source of energy, the spring, replaces the weights and allows them to be transported, their size is further reduced to evolve towards the table clock and finally lead to an ultimate reduction: the watch. Two centuries later, these successive improvements will result in significant watchmaking diversity, and the end of the 17th century will mark the advent of Comtois clocks and lanterns, the subject of this site...",
  },
  de: {
    title: "Mechanische Uhrmacherei",
    subtitle: "Als die Zeitmessung mechanisch wurde...",
    description:
      "Gegen Ende des 13. Jahrhunderts tauchten seltene mechanische Uhren auf, die dazu bestimmt waren, die kanonischen Stunden religiöser Gemeinschaften anzuzeigen. Während des 14. Jahrhunderts wurden diese monumentalen Uhren, die heute mit mächtigen Glockenschlägen ausgestattet sind, in Glockentürmen und Glockentürmen installiert und prägten so das Leben einiger europäischer Großstädte. Dann, um die reichen Innenräume zu bewohnen, werden sie in Form von Uhren in Miniaturbauweise erscheinen, wie sie, aus Eisen, Gewicht und Foliot, die gotische Uhr geboren wurde... Mitte des 15. Jahrhunderts eine neue Quelle von Energie, die Feder, ersetzt die Gewichte und ermöglicht deren Transport, ihre Größe wird weiter reduziert, um sich in Richtung der Tischuhr zu entwickeln und schließlich zu einer ultimativen Reduktion zu führen: der Uhr. Zwei Jahrhunderte später werden diese sukzessiven Verbesserungen zu einer bedeutenden uhrmacherischen Vielfalt führen, und das Ende des 17. Jahrhunderts wird das Aufkommen der Comtois-Uhren und -Laternen markieren, die Gegenstand dieser Website sind...",
  },
};

const mecaTrad = meca[currentLangage];

export default mecaTrad;
