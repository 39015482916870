import styled, { css } from "styled-components";
import { SideFade } from "../../style/animation";
import { query } from "../../style/mediaQueries";

// Default Style
const global = {
  container: (background, path) => css`
    display: flex;
    justify-content: center;
    background-image: url(${background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    height: ${path === "/home" ? "500px" : "auto"};
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0.8rem;
  `,
  subTitle: css`
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  `,
  description: css`
    font-size: 20px;
    line-height: 1.8rem;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
  `,
};

// Media Queries
const queries = {
  tablet: {
    container: query.tablet(css`
      width: 100%;
      height: 500px;
      min-height: auto;
    `),
    wrapper: query.tablet(css`
      margin: 0 auto;
      width: 700px;
    `),
    subTitle: query.tablet(css``),
    description: query.tablet(css`
      font-size: 1.2rem;
      line-height: 1.5rem;
    `),
  },
  laptop: {
    container: query.laptop(css``),
    wrapper: query.laptop(css``),
    subTitle: query.laptop(css``),
    description: query.laptop(css``),
  },
  desktop: {
    container: query.desktop(css``),
    wrapper: query.desktop(css``),
    subTitle: query.desktop(css``),
    description: query.desktop(css``),
  },
};

// Components
const Container = styled.div`
  background-color: #a0884f;
  max-height: 1.8rem;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  font-family: arial;
  color: #fff;
  width: 100%;
  font-size: 1.5rem;
  overflow: hidden;
  position: relative;
  ${queries.tablet.container}
  ${queries.laptop.container}
  ${queries.desktop.container}
`;

const Wrapper = styled(SideFade)`
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%;
  animation: scroll 40s linear infinite;
  ${global.wrapper}
  ${queries.tablet.wrapper}
  @keyframes scroll {
    from {
      transform: translateX(250%);
    }
    to {
      transform: translateX(-300%);
    }
  }
`;

const StyledAlert = {
  Container,
  Wrapper,
};

export { StyledAlert };
