import styled, { css } from "styled-components";
import { TitleContainer } from "../../components/Title/Title.style";
import { query } from "../../style/mediaQueries";

const defaultStyle = {
  container: css`
    display: flex;
    justify-content: center;
  `,
  wrapper: css`
    display: flex;
    justify-content: center;
    margin: 0 auto;
  `,
  title: css`
    border: 2px solid #a0884f;
    border-radius: 10px;
    padding: 0.8rem;
    margin: 2rem auto;
    background-color: #151515;
    font-size: 1rem;
  `,
};

const queries = {
  laptop: {
    wrapper: query.laptop(css`
      width: 700px;
    `),
    title: query.laptop(
      css`
        font-size: 3rem;
        width: 1100px;
      `
    ),
  },
  desktop: {
    title: query.desktop(css`
      font-size: 4rem;
    `),
  },
};

const Container = styled.div`
  ${defaultStyle.container}
`;

const Wrapper = styled.div`
  ${defaultStyle.wrapper}
  ${queries.laptop.wrapper}
`;

const HomeTitle = styled(TitleContainer)`
  ${defaultStyle.title}
  ${queries.laptop.title}
  ${queries.laptop.desktop}
`;

const StyledHome = {
  Container,
  Wrapper,
  Title: HomeTitle,
};

export { StyledHome };
