import { css } from "styled-components";
import { device } from "./device";

// Media queries
const query = {
  tablet: (rules) => css`
    @media screen and (${device.tablet}) {
      ${rules}
    }
  `,
  laptop: (rules) => css`
    @media screen and (${device.laptop}) {
      ${rules}
    }
  `,
  desktop: (rules) => css`
    @media screen and (${device.desktop}) {
      ${rules}
    }
  `,
};

export { query };
