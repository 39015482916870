import RieslingWoff2 from "../assets/fonts/riesling/riesling-webfont.woff2";
import RieslingWoff from "../assets/fonts/riesling/riesling-webfont.woff";
import RieslingTtf from "../assets/fonts/riesling/riesling.ttf";

import { createGlobalStyle } from "styled-components";

const FontStyles = createGlobalStyle`
@font-face {
  font-family: "riesling";
  src: url(${RieslingWoff2}) format("woff2"),
    url(${RieslingWoff}) format("woff"),
    url(${RieslingTtf}) format("ttf");
  font-weight: normal;
  font-style: normal;
}
`;

export { FontStyles };
