import { createContext } from "react";
import { useNavigate } from "react-router-dom";

export const langageContext = createContext({});

const LangageProvider = ({ children }) => {
  const navigate = useNavigate();
  const changeLangage = (langage) => {
    window.localStorage.setItem("currentLangage", langage);
    navigate(0);
  };

  return (
    <langageContext.Provider value={{ changeLangage }}>
      {children}
    </langageContext.Provider>
  );
};

export default LangageProvider;
