import { Helmet } from "react-helmet-async";
import { Template } from "../../components";
import { annonceMenuConf } from "../../configs/menuConf";
import { FadeIn } from "../../style/animation";

const bannerConf = {
  title: "Annonces",
  subTitle: "",
  description:
    "Cette rubrique d’échange se destine à tout ceux qui recherchent des livres, des outils, ou des horloges anciennes.  Les bourses et les rencontres horlogères organisées par l' A.F.A.H.A ainsi que d'autres grandes manifestations hors de France sont répertoriées à la page « évènements » . si vous désirez vendre ou acheter des outils, des horloges et tout ce qui concerne l’horlogerie ancienne, la page « matériels » est disponible pour publier gratuitement vos annonces. Il vous suffit d’envoyer une demande par la rubrique « contact » .",
};

const Announcement = () => {
  return (
    <>
      <Helmet>
        <title>Annonces</title>
        <meta
          name="description"
          content="Cette rubrique d’échange se destine à tout ceux qui recherchent des livres, des outils, ou des horloges anciennes.  Les bourses et les rencontres horlogères organisées par l' A.F.A.H.A ainsi que d'autres grandes manifestations hors de France sont répertoriées à la page « évènements » . si vous désirez vendre ou acheter des outils, des horloges et tout ce qui concerne l’horlogerie ancienne, la page « matériels » est disponible pour publier gratuitement vos annonces. Il vous suffit d’envoyer une demande par la rubrique « contact » ."
        />
        <link rel="canonical" href="/announcement" />
      </Helmet>
      <FadeIn>
        <Template
          bannerConf={bannerConf}
          menuConf={annonceMenuConf}
          category={"annonce"}
        />
      </FadeIn>
    </>
  );
};

export default Announcement;
