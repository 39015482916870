// import ImageIcon from "@mui/icons-material/Image";
// import { Files } from "..";
import { tools } from "../../../configs/tools.conf";
// import { useRef } from "react";
import "./ContentToolBar.scss";

const CLASS = "global-container";

const ContentToolBar = ({
  // files,
  // fileAction,
  selector,
  handleSubmit,
  notification,
  loader,
}) => {
  // const fileInputRef = useRef(null);
  return (
    <div className={`contentToolbar__${CLASS}`}>
      <div className={`contentToolbar__${CLASS}__top`}>
        {tools.map(({ Icon, title, type, action }, index) => {
          return (
            <button
              key={index}
              className="contactForm__icon"
              title={title}
              disabled={true}
              onClick={(e) => selector(e, type, action)}
            >
              <Icon />
            </button>
          );
        })}
      </div>
      {/* <Files files={files} fileAction={fileAction} /> */}
      <div className={`contentToolbar__${CLASS}__bottom`}>
        <button
          disabled={loader ? true : false}
          type="button"
          onClick={() => handleSubmit()}
        >
          {loader ? <span class="loader"></span> : "Envoyer"}
        </button>
        <p>
          merci de bien vouloir envoyer votre texte, je vous contacterai pour
          les photos
        </p>
        {/* <div className={`contentToolbar__${CLASS}__bottom-image-uploads`}>
          {files.length < 3 && (
            <button onClick={() => fileInputRef.current.click()}>
              <ImageIcon />
              <span>Ajouter des images</span>
              <input
                type="file"
                accept="image/*"
                multiple
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => fileAction.change(e)}
              />
            </button>
          )}
        </div> */}
        {notification.message && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentToolBar;
