import { Helmet } from "react-helmet-async";
import { Template } from "../../components";
import { mecaTrad } from "../../utils/translate";
import { mecaMenuConf } from "../../configs/menuConf";
import { FadeIn } from "../../style/animation";

const Meca = () => {
  return (
    <>
      <Helmet>
        <title>Horloges d'autrefois - Mécanique</title>
        <meta
          name="description"
          content="Vers la fin du XIIIe siècle, les premières horloges mécaniques destinées à marquer les heures canoniales des communautés religieuses font leur apparition. Tout au long du XIVe siècle, des horloges monumentales dotées de sonneries puissantes sont progressivement installées dans les beffrois et les clochers de quelques grandes villes afin d'y rythmer la vie de leurs habitants... Un peu plus tard, ces horloges se déclineront dans des proportions plus réduites pour habiter de riches intérieurs; l’horloge gothique était née… Puis, au milieu du XVe siècle, l’arrivée du barillet à ressort autorise leur mobilité et entraine une diminution de leur taille pour évoluer vers l’horloge de table . Avec l’arrivée du XVIe siècle leur volume se limitera encore pour aboutir à la montre en une ultime réduction . Ces améliorations successives se traduiront par une importante diversification de la production horlogère qui donnera naissance à l’horloge lanterne puis à la comtoise à la fin du XVIIe siècle."
        />
        <link rel="canonical" href="/meca" />
      </Helmet>
      <FadeIn>
        <Template
          bannerConf={mecaTrad}
          menuConf={mecaMenuConf}
          category={"mecanique"}
        />
      </FadeIn>
    </>
  );
};

export default Meca;
