import PropTypes from "prop-types";
import StyledButton from "./Button.style";

// Composant Button qui prend plusieurs props
const Button = ({ type, to, onClick, children }) => {
  // Si le type est "button", renvoyer un bouton cliquable
  return type === "button" ? (
    <StyledButton.Button onClick={onClick}>{children}</StyledButton.Button>
  ) : (
    // Sinon, renvoyer un lien cliquable
    <StyledButton.Link onClick={onClick} to={to}>
      {children}
    </StyledButton.Link>
  );
};

// Validation des types des props
Button.propTypes = {
  type: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Button;
