import { useState } from "react";

const useForm = () => {
  const [form, setForm] = useState({ name: "", email: "", subject: "" });

  const change = (e, name, clear) => {
    e.preventDefault();
    setForm((prevForm) => ({
      ...prevForm,
      [name]: clear ? "" : e.target.value,
    }));
  };

  const inputAction = { change };

  return { form, setForm, inputAction };
};

export default useForm;
