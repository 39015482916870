import { useClock } from "../../hooks";
import { OuterCircle, Hour, Minute, Pin, Second } from "./Needles.style";
import { hourNeedle, minuteNeedle, secondNeedle } from "../../assets";

const Needles = ({ size }) => {
  const { hourRotation, minuteRotation, secondRotation } = useClock();
  return (
    <OuterCircle size={size}>
      <Hour backgroundImage={hourNeedle} hourRotation={hourRotation} />
      <Minute backgroundImage={minuteNeedle} minuteRotation={minuteRotation} />
      {/* <Second
        backgroundImage={secondNeedle}
        minuteRotation={minuteRotation}
        secondRotation={secondRotation}
      /> */}
      {/* <Pin /> */}
    </OuterCircle>
  );
};

export default Needles;
