import PropTypes from "prop-types";
import { TitleContainer } from "./Title.style";

const Title = ({ children }) => {
  return <TitleContainer>{children}</TitleContainer>;
};

// Validation des types des props
Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
