import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => css`
    width: ${size + 1.7}vw;
    height: ${size + 1.7}vw;
  `}
  border: 1px solid #000;
  border-radius: 100%;
  transform: rotate(2deg);
`;

const Rayon = styled.div`
  position: absolute;
  width: 3px;
  background-color: #000;
  height: ${({ size }) => size + 1.7}vw;
  transform: rotate(${({ rotation }) => rotation}deg);
`;

export { Container, Rayon };
