import { Button } from "../";
import { PitchContainer, Wrapper, Title, Desc, Sign } from "./Pitch.style";
import { discoveryTrad } from "../../utils/translate";

const Pitch = () => {
  return (
    <PitchContainer>
      <Wrapper>
        <Title>{discoveryTrad.title}</Title>
        <Desc>{discoveryTrad.description}</Desc>
        <Sign>Gilles Ribierre</Sign>
      </Wrapper>
      <Button type="link" to="/home">
        {discoveryTrad.button}
      </Button>
    </PitchContainer>
  );
};

export default Pitch;
