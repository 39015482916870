import { Helmet } from "react-helmet-async";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Horloges d'autrefois - Mentions Légales</title>
        <link rel="canonical" href="/privacy" />
      </Helmet>
      <section className="privacy__global-container">
        <div className="privacy__wrapper">
          <h1>Mentions Légales</h1>
          <div>
            <>
              <p>
                Les présentes conditions générales fixent les modalités
                d’utilisation du service web{" "}
                <a
                  href="https://horlogesdautrefois.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://horlogesdautrefois.fr
                </a>
              </p>
              <p>
                L’utilisateur reconnait avoir pris connaissance de ce document
                et accepté l’ensemble de ces informations, que cet usage soit
                fait à titre personnel ou professionnel. Si cet usage est fait à
                titre professionnel, l’utilisateur garantit détenir les pouvoirs
                nécessaires pour accepter ces conditions générales au sein de
                son organisation.
              </p>
            </>
            <>
              <h2>Hébergeur</h2>
              <p>GANDI SAS</p>
              <p>63-65 boulevard Masséna Paris (75013) FRANCE</p>
              <p>+33 (0) 1 70.37.76.61</p>
            </>
            <>
              <h2>Éditeur et responsable de publication</h2>
              <p>
                Ce blog personnel non déclaré à la CNIL est édité par Gilles
                Ribierre
              </p>
            </>
            <>
              <h2>Limitations contractuelles sur les données techniques</h2>
              <p>
                Le site utilise la technologie JavaScript. Le site Internet ne
                pourra être tenu responsable de dommages matériels liés à
                l’utilisation du site. De plus, l’utilisateur du site s’engage à
                accéder au site en utilisant un matériel récent, ne contenant
                pas de virus et avec un navigateur de dernière génération
                mis-à-jour
              </p>
            </>
            <>
              <h2>Propriété intelectuelle</h2>
              <p>
                Gilles Ribierre est propriétaire des droits de propriété
                intellectuelle ou détient les droits d’usage sur tous les
                éléments accessibles sur le site, notamment les textes, images,
                logo (sauf mentions contraires). Toute reproduction,
                représentation, modification, publication, adaptation de tout ou
                partie des éléments du site, quel que soit le moyen ou le
                procédé utilisé, est interdite, sauf autorisation écrite
                préalable.Toute exploitation non autorisée du site ou de l’un
                quelconque des éléments qu’il contient sera considérée comme
                constitutive d’une contrefaçon et poursuivie conformément aux
                dispositions des articles L.335-2 et suivants du Code de
                Propriété Intellectuelle. Cela dit, conformément à l’article L.
                122-5 du Code de la propriété intellectuelle, les courtes
                citations du contenu sont autorisées, sous réserve que soient
                indiqués clairement le nom de l’auteur et de la source, par un
                lien vers une des pages de ce site web.
              </p>
            </>
            <>
              <h2>Traitement des données personnelles</h2>
              <p>
                En France, les données personnelles sont notamment protégées par
                la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août
                2004, l’article L. 226-13 du Code pénal et la Directive
                Européenne du 24 octobre 1995. A l’occasion de l’utilisation du
                site{" "}
                <a
                  href="https://oiseaurose.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://horlogesdautrefois.fr
                </a>
                , peuvent êtres recueillies : l’URL des liens par
                l’intermédiaire desquels l’utilisateur a accédé au site, le
                fournisseur d’accès de l’utilisateur, l’adresse de protocole
                Internet (IP) de l’utilisateur. En tout état de cause, les
                informations personnelles relatives à l’utilisateur ne sont
                collectés que pour le besoin de certains services proposés par
                le site{" "}
                <a
                  href="https://horlogesdautrefois.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://horlogesdautrefois.fr
                </a>
                . L’utilisateur fournit ces informations en toute connaissance
                de cause, notamment lorsqu’il procède par lui-même à leur
                saisie. Il est alors précisé à l’utilisateur du site
                l’obligation ou non de fournir ces informations. Conformément
                aux dispositions des articles 38 et suivants de la loi 78-17 du
                6 janvier 1978 relative à l’informatique, aux fichiers et aux
                libertés, tout utilisateur dispose d’un droit d’accès, de
                rectification et d’opposition aux données personnelles le
                concernant, en effectuant sa demande écrite et signée,
                accompagnée d’une copie du titre d’identité avec signature du
                titulaire de la pièce, en précisant l’adresse à laquelle la
                réponse doit être envoyée. Aucune information personnelle de
                l’utilisateur du site{" "}
                <a
                  href="https://horlogesdautrefois.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://horlogesdautrefois.fr
                </a>{" "}
                n’est publiée à l’insu de l’utilisateur, échangée, transférée,
                cédée ou vendue sur un support quelconque à des tiers. Seule
                l’hypothèse du rachat du site et de ses droits permettrait la
                transmission des dites informations à l’éventuel acquéreur qui
                serait à son tour tenu de la même obligation de conservation et
                de modification des données vis à vis de l’utilisateur du site{" "}
                <a
                  href="https://horlogesdautrefois.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://horlogesdautrefois.fr
                </a>
                .
              </p>
              <p>
                Le site n’est pas déclaré à la CNIL car il ne recueille pas
                d’informations personnelles. .
              </p>
              <p>
                Les bases de données sont protégées par les dispositions de la
                loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars
                1996 relative à la protection juridique des bases de données.
              </p>
            </>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
