import styled, { keyframes } from "styled-components";

// Animation
const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

const sideFadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Styles
const FadeIn = styled.div`
  animation: ${fadeInAnimation} 3s forwards;
`;

const SideFade = styled.div`
  animation: ${sideFadeAnimation} 1s ease-in-out;
`;

export { FadeIn, SideFade };
