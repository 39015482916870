import styled from "styled-components";

const LogoContainer = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "riesling";
  letter-spacing: 2px;
  color: #a0884f;
  padding: 0.3rem;
  z-index: 3;
`;

const Top = styled.span`
  font-size: 1.5rem;
  margin: 1.5rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Bottom = styled.span`
  font-size: 2.8rem;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export { LogoContainer, Top, Bottom };
