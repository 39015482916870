import { InnerCircle, Container } from "./Dial.style";
import { dial } from "../../assets";

const Dial = ({ children, size }) => {
  return (
    <InnerCircle size={size}>
      <Container size={size} backgroundImage={dial}>
        {children}
      </Container>
    </InnerCircle>
  );
};

export default Dial;
