import styled, { css } from "styled-components";
import { query } from "../../style/mediaQueries";
import { Link } from "react-router-dom";

const defaultStyle = {
  button: css`
    text-decoration: none;
    padding: 0.8rem;
    color: #fff;
    border: none;
    font-family: Helvetica, sans-serif;
    font-size: 1.5rem;
    letter-spacing: 4px;
    text-transform: uppercase;
    transition: ease-in-out 0.5s;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-position: 99% 50%;
    }
    margin: 0 0.4rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  `,
};

// Media Queries
const queries = {
  tablet: {
    button: query.tablet(css`
      border: 1px solid rgba(223, 190, 106, 0.3);
      background: linear-gradient(
        270deg,
        rgba(223, 190, 106, 0.8),
        rgba(146, 111, 52, 0.8),
        rgba(34, 34, 34, 0),
        rgba(34, 34, 34, 0)
      );
      background-position: 1% 50%;
      background-size: 300% 300%;
      letter-spacing: 1px;
      font-size: 11px;
      color: rgba(223, 190, 106, 0.7);
    `),
  },
  laptop: {
    button: query.tablet(css``),
  },
  desktop: {
    button: query.desktop(css`
      font-size: 1.3rem;
    `),
  },
};

const Button = styled.button`
  ${defaultStyle.button}
  ${queries.tablet.button}
  ${queries.laptop.button}
  ${queries.desktop.button}
`;

const ImportedLink = styled(Link)`
  ${defaultStyle.button}
  ${queries.tablet.button}
  ${queries.laptop.button}
  ${queries.desktop.button}
`;

const StyledButton = {
  Button,
  Link: ImportedLink,
};

export default StyledButton;
