import { Input } from "..";
import "./Inputs.scss";

const Inputs = ({ inputs, inputChange, form }) => {
  return inputs.map(({ type, name, placeholder }, index) => (
    <Input
      type={type}
      name={name}
      placeholder={placeholder}
      value={form[name]}
      onChange={(e) => inputChange(e, name)}
      clearState={(e) => inputChange(e, name, true)}
      key={index}
    />
  ));
};

export default Inputs;
