import styled, { css } from "styled-components";

const global = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  transform-origin: bottom;
  z-index: 2;
  border-radius: 2em;
  width: 13%;
  position: absolute;
  bottom: 50%;
  left: 50%;
  background-size: cover;
  background-position: center;
  transform-origin: bottom;
  z-index: 8;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
`;

const OuterCircle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  ${({ size }) => css`
    width: ${size}vw;
    height: ${size}vw;
  `}
  border-radius: 100%;
  background-color: transparent;
`;

const Hour = styled.div`
  ${global}
  transform-origin: 50% 80%;
  height: 37%;
  transform: ${({ hourRotation }) =>
    css`translate(-48%,-79%) rotate(${hourRotation}deg)`};
  background-image: url(${({ backgroundImage }) => backgroundImage});
`;

const Minute = styled.div`
  ${global}
  height: 60%;
  transform: ${({ minuteRotation }) =>
    css`translate(-55%,-100%) rotate(${minuteRotation}deg)`};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  width: 15%;
`;

const Second = styled.div`
  ${global}
  height: 150%;
  transform: ${({ secondRotation }) =>
    css`translate(-40%,-95%) rotate(${secondRotation}deg)`};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  width: 15%;
`;

const Pin = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 46%;
  bottom: 0;
  width: 6%;
  height: 6%;
  background: #000;
  border: 2px solid #000;
  border-radius: 10em;
  margin: auto;
  z-index: 10;
`;

export { OuterCircle, Hour, Minute, Pin, Second };
