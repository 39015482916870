import styled from "styled-components";

const TitleContainer = styled.h1`
  color: #a0884f;
  font-family: "riesling";
  letter-spacing: 5px;
  font-size: 4rem;
  margin: 1rem;
  text-align: center;
`;

export { TitleContainer };
