import styled from "styled-components";
import { titleColor, titleFont } from "../../style/variables";

const SliderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SliderTitle = styled.span`
  top: 0;
  color: ${titleColor};
  font-family: ${titleFont};
  letter-spacing: 5px;
  font-size: 2.5rem;
  margin: 1rem;
`;

const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 500px;
  margin: 2rem;
  overflow: hidden;
`;

const SliderImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
`;

const SliderSubWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  transition: ease-in-out 0.3s;
  transform: ${(props) => `translateX(-${props.position}px)`};
`;

const SliderButton = styled.button`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  border: none;
  background-color: #212121;
  transition: ease-in-out 0.3s;
  &:hover {
    background-color: ${titleColor};
  }
  &::before,
  &::after {
    content: "";
    display: block;
    width: 5px;
    border: 2px solid #fff;
  }
  &::before {
    transform: rotate(-50deg);
  }
  &::after {
    transform: rotate(50deg);
  }
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Left = styled(SliderButton)`
  left: 1rem;
`;

const Right = styled(SliderButton)`
  transform: rotate(180deg);
  right: 1rem;
`;

export {
  SliderContainer,
  SliderWrapper,
  SliderSubWrapper,
  SliderImageContainer,
  SliderTitle,
  SliderButton,
  Left,
  Right,
};
