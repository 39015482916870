import PropTypes from "prop-types";
import { useDisplayComponent } from "../../hooks";
import { useState } from "react";
import { StyledMenu } from "./Menu.style";
import horologyLogo from "../../assets/img/Logo.png";

const Menu = ({ type, conf }) => {
  const { isDisplay } = useDisplayComponent();
  const [isActive, setIsActive] = useState(false);
  return (
    isDisplay && (
      <StyledMenu.Container type={type}>
        <StyledMenu.Link to="/">
          {type === "main" && (
            <StyledMenu.Logo
              type={type}
              src={horologyLogo}
              alt="la lettre H avec une bordure dorée"
            />
          )}
        </StyledMenu.Link>
        <StyledMenu.Links type={type} isActive={isActive}>
          {conf.map(({ name, to, type }) => (
            <StyledMenu.Button
              key={name}
              type={type}
              to={to}
              onClick={() => setIsActive(false)}
            >
              {name}
            </StyledMenu.Button>
          ))}
        </StyledMenu.Links>
        <StyledMenu.Burger
          type={type}
          isActive={isActive}
          setIsActive={setIsActive}
        />
      </StyledMenu.Container>
    )
  );
};

// Validation des types des props
Menu.propTypes = {
  type: PropTypes.string,
  conf: PropTypes.array,
};

export default Menu;
