import styled from "styled-components";

const Container = styled.div`
  position: relative;
  background-image: url(${({ background }) => background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  width: 40vw;
  height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
`;

const Point = styled.div`
  position: absolute;
  height: 45vw;
  width: 1px;
  background-color: red;
  z-index: 9999;
`;
const Point2 = styled.div`
  position: absolute;
  height: 1px;
  width: 45vw;
  background-color: red;
  z-index: 9999;
`;

const Circle = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 100%;
  z-index: 9999;
  display: none;
`;

const Circle2 = styled.div`
  position: absolute;
  height: 367px;
  width: 367px;
  border: 1px solid red;
  border-radius: 100%;
  z-index: 9999;
`;

export { Container, Circle, Circle2, Point, Point2 };
