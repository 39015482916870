import PropTypes from "prop-types";
import { StyledMasonry } from "./Masonry.style";
import { Card, Loading } from "../";
import useFetch from "../../hooks/useFetch";

const Masonry = ({ url }) => {
  const [loading, items] = useFetch(url);

  if (loading) return <Loading>Chargement des articles...</Loading>;

  if (!items) return <Loading>Aucun articles pour le moment</Loading>;
  console.log(items);
  return (
    <StyledMasonry.Container>
      <StyledMasonry.Grid pos={items.length === 1 ? true : false}>
        {items?.map(({ background, title, description, id }) => (
          <Card
            key={Math.floor(Math.random() * Date.now())}
            background={background}
            title={title}
            description={description}
            id={id}
          />
        ))}
      </StyledMasonry.Grid>
    </StyledMasonry.Container>
  );
};

Masonry.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Masonry;
