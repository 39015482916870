import { Dial, Needles } from "../";
import { GlobalContainer } from "./Clock.style";
import { size } from "../../configs";

const Clock = () => {
  return (
    <GlobalContainer size={size}>
      <Dial size={size}>
        <Needles size={size} />
      </Dial>
    </GlobalContainer>
  );
};

export default Clock;
