import { currentLangage } from "../currentLangage";

const workshop = {
  fr: {
    title: "Atelier",
    description:
      "La restauration des pendules anciennes est un vaste domaine nécessitant des connaissances dans de multiples domaines. L’objet de ces articles n’est pas de former des horlogers, mais d’orienter ceux qui se passionnent pour la restauration des horloges anciennes par des exemples simples et pratiques. Comment nettoyer un mouvement, remplacer un pivot cassé, se servir d’un tour d’horloger, d’une potence, la réponse à ces questions est ici…",
  },
  en: {
    title: "Workshop",
    description:
      "The restoration of old clocks is a vast field requiring knowledge in multiple fields. The purpose of these articles is not to train watchmakers, but to guide those who are passionate about the restoration of old clocks with simple and practical examples. How to clean a movement, replace a broken pivot, use a watchmaker's lathe, a gallows, the answer to these questions is here...",
  },
  de: {
    title: "Werkstatt",
    description:
      "Die Restaurierung alter Uhren ist ein weites Feld, das Kenntnisse auf mehreren Gebieten erfordert. Der Zweck dieser Artikel ist nicht, Uhrmacher auszubilden, sondern diejenigen, die sich leidenschaftlich für die Restaurierung alter Uhren interessieren, mit einfachen und praktischen Beispielen anzuleiten. Wie man ein Uhrwerk reinigt, einen gebrochenen Zapfen ersetzt, eine Uhrmacherdrehbank benutzt, einen Galgen, die Antwort auf diese Fragen finden Sie hier...",
  },
};

const workshopTrad = workshop[currentLangage];

export default workshopTrad;
