import styled, { keyframes } from "styled-components";

/* Config */
const colorBg = "#101010";
const colorParticle = "#a0884f";
const spacing = "2560px";
const time1 = "60s";
const time2 = "120s";
const time4 = "200s";

/* Mixins */
const particles = (max) => {
  let val = `0px 0px ${colorParticle}`;
  for (let i = 1; i <= max; i++) {
    val += `, ${Math.floor(Math.random() * parseInt(spacing))}px ${Math.floor(
      Math.random() * parseInt(spacing)
    )}px ${colorParticle}`;
  }
  return val;
};

// KeyFrames
const animParticle = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-${parseInt(spacing)}px);
  }
`;

const particleMixin = (max) => `
  box-shadow: ${particles(max)};
  border-radius: 50%;
`;

/* Styles */
const PageBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: ${colorBg};
  background-blend-mode: screen;
  background-size: cover;
  filter: grayscale(100%);
`;

const AnimationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Particle = styled.div`
  background: transparent;
  &:after {
    position: absolute;
    content: "";
    top: ${spacing};
  }
`;

const Particle1 = styled(Particle)`
  animation: ${animParticle} ${time1} linear infinite;
  ${particleMixin(100)};
  height: 2px;
  width: 2px;
  &:after {
    ${particleMixin(200)};
    height: 2px;
    width: 2px;
  }
`;

const Particle2 = styled(Particle)`
  animation: ${animParticle} ${time2} linear infinite;
  ${particleMixin(250)};
  height: 2px;
  width: 2px;
  &:after {
    ${particleMixin(200)};
    height: 3px;
    width: 3px;
  }
`;

const Particle3 = styled(Particle)`
  &:after {
    ${particleMixin(360)};
    height: 3px;
    width: 3px;
    border-radius: 50%;
  }
`;

const Particle4 = styled(Particle)`
  animation: ${animParticle} ${time4} linear infinite;
  ${particleMixin(200)};
  height: 1px;
  width: 1px;
  &:after {
    ${particleMixin(100)};
    height: 1px;
    width: 1px;
  }
`;

export {
  PageBackground,
  AnimationWrapper,
  Particle,
  Particle1,
  Particle2,
  Particle3,
  Particle4,
};
