import { Helmet } from "react-helmet-async";
import { Alert, Banner, FeedBack, Loading, Title } from "../../components";
import { FadeIn } from "../../style/animation";
import { StyledHome } from "./Home.style";
import homeTrad from "../../utils/translate/pages/home";
import { useFetch } from "../../hooks";
const Home = () => {
  const [loading, items] = useFetch(
    "https://www.admin.horlogesdautrefois.fr/api/articles/date"
  );

  const item = items.category ? items : null;

  return (
    <>
      <Helmet>
        <title>Horloges d'autrefois - Accueil</title>
        <meta
          name="description"
          content="site destiné à ceux qui se passionnent pour les horloges lanternes, les Comtoises, les Saint Nicolas, et toutes ces merveilles qui ont égrené les heures d’autrefois."
        />
        <link rel="canonical" href="/home" />
      </Helmet>
      <FadeIn>
        <Banner title="" subtitle="" description="" />
        <StyledHome.Title>
          Comtoises, Lanternes, Horloges D'autrefois
        </StyledHome.Title>
        <Alert text={"Vers"} />
        <StyledHome.Wrapper>
          {/* {loading ? (
              <Loading>Chargement de l'article</Loading>
            ) : (
              <FeedBack cards={item} title={homeTrad["article"]} />
            )} */}
        </StyledHome.Wrapper>
      </FadeIn>
    </>
  );
};

export default Home;
