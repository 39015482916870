// Style
import "./Loading.scss";

const Loading = ({ children }) => {
  return (
    <div className="loading__global-container">
      <span>{children}</span>
    </div>
  );
};

export default Loading;
