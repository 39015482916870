import { Helmet } from "react-helmet-async";
import { FadeIn } from "../../style/animation";
import { Form } from "../../components/Form";
import "./Contact.scss"; // Importation des styles SCSS

const Contact = () => {
  return (
    <FadeIn>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="contact__global-container">
        <Form />
      </div>
    </FadeIn>
  );
};

export default Contact;
