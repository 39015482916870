import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => css`
    width: ${size + 20}vw;
    height: ${size + 20}vw;
  `}
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  position: relative;
  background-image: ${({ backgroundImage }) => css`url(${backgroundImage})`};
`;

export { Container };
