import PropTypes from "prop-types";
import "./Input.scss";

const Input = ({
  type,
  name,
  placeholder,
  value,
  onChange,
  clearState,
  ...props
}) => {
  return (
    <div className="input__global-container">
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
      <button type="button" onClick={clearState}>
        ✕
      </button>
    </div>
  );
};

// Conf exemple
// const inputs = [
//   { type: "text", name: "name", placeholder: "Votre nom" },
//   { type: "email", name: "email", placeholder: "Votre adresse E-mail" },
//   { type: "text", name: "subject", placeholder: "L'objet" },
// ];

Input.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Input;
