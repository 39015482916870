import { Editor } from "draft-js";
import "./ContentEditor.scss";

const ContentEditor = ({
  editorState,
  handleEditorStateChange,
  blockStyleFn,
}) => {
  return (
    <div className="contentEditor__global-container">
      <Editor
        placeholder="Votre Message"
        editorState={editorState}
        onChange={handleEditorStateChange}
        blockStyleFn={blockStyleFn}
      />
    </div>
  );
};

export default ContentEditor;
