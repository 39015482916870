let radius = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
];

let size = 20;

let numbers = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

export { radius, size, numbers };
