import { useLocation } from "react-router-dom";

// Img
import home from "../assets/img/background/home.jpg";
import meca from "../assets/img/background/meca.png";
import elec from "../assets/img/background/elec.png";
import announcement from "../assets/img/background/announcement.png";
import workshop from "../assets/img/background/workshop.png";

const useBackground = () => {
  const { pathname } = useLocation();
  const current = pathname.split("/").join("");

  const backgrounds = {
    home,
    meca,
    elec,
    announcement,
    workshop,
  };

  const background = backgrounds[current];

  return background;
};

export default useBackground;
