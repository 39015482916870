import { StyledSort } from "./Sort.style";
import Button from "../Button/Button";
import { useRef } from "react";

const Sort = ({ conf, mobileSize, setSort }) => {
  const currentRef = useRef();
  return (
    <StyledSort.Container>
      {mobileSize ? (
        <>
          <StyledSort.Title>Trier les articles: </StyledSort.Title>
          <StyledSort.Select
            ref={currentRef}
            onChange={() =>
              setSort(
                `https://www.admin.horlogesdautrefois.fr/${currentRef.current.value}`
              )
            }
          >
            {conf.map(({ name, to, type }) => (
              <StyledSort.Option value={to}>{name}</StyledSort.Option>
            ))}
          </StyledSort.Select>
        </>
      ) : (
        <>
          {conf.map(({ name, to, type }) =>
            name === "Historique" ? (
              <Button type="link" to="/article/2">
                {name}
              </Button>
            ) : name === "L'électromagnétisme" ? (
              <Button type="link" to="/article/3">
                {name}
              </Button>
            ) : name === "Remerciements" ? (
              <Button type="link" to="/article/46">
                {name}
              </Button>
            ) : (
              <Button
                onClick={() =>
                  setSort(`https://www.admin.horlogesdautrefois.fr/${to}`)
                }
                type="button"
              >
                {name}
              </Button>
            )
          )}
        </>
      )}
    </StyledSort.Container>
  );
};

export default Sort;
