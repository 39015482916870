import { Helmet } from "react-helmet-async";
import { Template } from "../../components";
import { workShopTrad } from "../../utils/translate";
import { workShopMenuConf } from "../../configs/menuConf";
import { FadeIn } from "../../style/animation";

const WorkShop = () => {
  return (
    <>
      <Helmet>
        <title>Horloges d'autrefois - Atelier</title>
        <meta
          name="description"
          content="La restauration des pendules anciennes est un vaste domaine nécessitant des connaissances dans de multiples domaines."
        />
        <link rel="canonical" href="/workshop" />
      </Helmet>
      <FadeIn>
        <Template
          bannerConf={workShopTrad}
          menuConf={workShopMenuConf}
          category={"atelier"}
        />
      </FadeIn>
    </>
  );
};

export default WorkShop;
