import PropTypes from "prop-types";
import { StyledCard } from "./Card.style";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";

const Card = ({ background, title, description, id }) => {
  const navigate = useNavigate();
  return (
    <StyledCard.Container>
      <StyledCard.Image
        src={background}
        onClick={() => navigate(`/article/${id}`)}
      />
      <StyledCard.Wrapper>
        <StyledCard.Title>{title}</StyledCard.Title>
        <StyledCard.DescriptionContainer>
          <StyledCard.Description>{description}</StyledCard.Description>
        </StyledCard.DescriptionContainer>
        <StyledCard.ButtonContainer>
          <Button type="link" to={`/article/${id}`}>
            Lire l'article
          </Button>
        </StyledCard.ButtonContainer>
      </StyledCard.Wrapper>
    </StyledCard.Container>
  );
};

// Validation des types des props
Card.propTypes = {
  background: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default Card;
