import styled, { css } from "styled-components";
import { query } from "../../style/mediaQueries";

// Default Style
const defaultStyle = {
  container: css`
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    margin: 0 auto;
    max-width: 900px;
  `,
  grid: css`
    display: flex;
    flex-direction: column;
  `,
};

// Media queries
const queries = {
  tablet: {
    container: query.tablet(css`
      max-width: auto;
    `),
    grid: query.tablet(css`
      display: block;
      column-count: 2;
      column-gap: 0;
    `),
  },
  laptop: {
    grid: query.laptop(css`
      column-count: 3;
    `),
  },
};

const Container = styled.div`
  ${defaultStyle.container}
`;

const Grid = styled.div`
  ${defaultStyle.grid}
  ${queries.laptop.grid}
  ${queries.tablet.grid}
  ${({ pos }) =>
    pos &&
    css`
      display: flex !important;
      justify-content: center;
    `}
`;

const StyledMasonry = {
  Container,
  Grid,
};

export { StyledMasonry };
