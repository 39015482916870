import styled from "styled-components";

const DiscoveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  padding: 1rem;
  z-index: 9999;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 0;
    margin: 1.5rem;
  }
`;

const DiscoveryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 0;
    margin: 1.5rem;
  }
`;

const DiscoveryBanner = styled.div`
  border: 2px solid #a0884f;
  border-radius: 10px;
  padding: 0.8rem;
  margin: 2rem auto;
  background-color: #151515;
  font-size: 4rem;
  font-family: "Riesling";
  color: #a0884f;
`;

export { DiscoveryContainer, DiscoveryWrapper, DiscoveryBanner };
