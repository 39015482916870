import { Container } from "./index.style";
import { Clock } from "./components";
import { dial2 } from "./assets";

const Horloge = () => {
  return (
    <Container background={dial2}>
      <Clock />
    </Container>
  );
};

export default Horloge;
