import { Helmet } from "react-helmet-async";
import { Horloge, Particles, Pitch, Button } from "../../components";
import { discoveryTrad } from "../../utils/translate";
import {
  DiscoveryBanner,
  DiscoveryContainer,
  DiscoveryWrapper,
} from "./Discovery.style";
import { Link } from "react-router-dom";

const Discovery = () => {
  return (
    <Link to={"/home"} style={{ textDecoration: "none" }}>
      <Helmet>
        <title>Horloges d'autrefois - Faisons connaissance…</title>
        <meta name="description" content="Faisons connaissance" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <DiscoveryContainer>
        <DiscoveryBanner>
          Comtoises, Lanternes, Horloges D'autrefois
        </DiscoveryBanner>
        <DiscoveryWrapper>
          <Horloge />
          <Pitch />
        </DiscoveryWrapper>

        <Particles />
      </DiscoveryContainer>
    </Link>
  );
};

export default Discovery;
