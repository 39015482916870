import styled, { css } from "styled-components";
import { device } from "../../style/device";

const buttonIsActive = (isActive) => {
  switch (isActive) {
    case true:
      return css`
        ${BurgerBar1} {
          transform: rotate(45deg) translateY(12px);
        }
        ${BurgerBar2} {
          opacity: 0;
        }
        ${BurgerBar3} {
          transform: rotate(-45deg) translateY(-12px);
        }
      `;
    default:
      break;
  }
};

const mediaQueries = css`
  @media screen and (${device.laptop}) {
    display: none;
  }
`;

const BurgerBars = styled.div`
  width: inherit;
  height: 3px;
  background-color: #fff;
  transition: ease-in-out 0.3s;
  border-radius: 10px;
`;

const BurgerBar1 = styled(BurgerBars)``;

const BurgerBar2 = styled(BurgerBars)``;

const BurgerBar3 = styled(BurgerBars)``;

const BurgerContainer = styled.button`
  width: 25px;
  height: 20px;
  display: ${({ type }) => (type === "main" ? "flex" : "none")};
  background-color: transparent;
  border: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  ${({ isActive }) => buttonIsActive(isActive)}
  ${mediaQueries}
`;

export { BurgerContainer, BurgerBars, BurgerBar1, BurgerBar2, BurgerBar3 };
