import styled, { css } from "styled-components";
import { query } from "../../style/mediaQueries";

// Default Style
const defaultStyle = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 1rem;
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,
  title: css`
    color: #fff;
    font-family: arial;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  `,
};

// Media Queries
const queries = {
  tablet: {
    container: query.tablet(css``),
    wrapper: query.tablet(css``),
    title: query.tablet(css``),
  },
  laptop: {
    container: query.laptop(css``),
    wrapper: query.laptop(css``),
    title: query.laptop(css``),
  },
  desktop: {
    container: query.desktop(css``),
    wrapper: query.desktop(css``),
    title: query.desktop(css``),
  },
};

const Container = styled.div`
  ${defaultStyle.container}
  ${queries.tablet.container}
  ${queries.laptop.container}
  ${queries.desktop.container}
`;

const Wrapper = styled.div`
  ${defaultStyle.wrapper}
  ${queries.tablet.wrapper}
  ${queries.laptop.wrapper}
  ${queries.desktop.wrapper}
`;

const Title = styled.span`
  ${defaultStyle.title}
  ${queries.tablet.title}
  ${queries.laptop.title}
  ${queries.desktop.title}
`;

const StyledFeedBack = {
  Container,
  Wrapper,
  Title,
};

export { StyledFeedBack };
