import { currentLangage } from "../currentLangage";

const menuTrad = {
  fr: {
    home: "Accueil",
    meca: "Horlogerie mécanique",
    elec: "Horlogerie électrique",
    workshop: "Atelier",
    announcement: "Annonces",
    contact: "Contact",
    me: "Admin",
  },
  // en: {
  //   home: "Home",
  //   meca: "Mechanical horology",
  //   elec: "Electric horology",
  //   workshop: "Workshop",
  //   announcement: "Announcements",
  //   contact: "Contact",
  //   me: "Admin",
  // },
  // de: {
  //   home: "Startseite",
  //   meca: "Mechanische Uhrmacherei",
  //   elec: "Elektrische Uhren",
  //   workshop: "Werkstatt",
  //   announcement: "Anzeigen",
  //   contact: "Kontakt",
  //   me: "Admin",
  // },
};

const mecaTrad = {
  fr: {
    allArticle: "Tous les articles",
    comtoise: "Comtoises",
    lanterne: "Lanternes",
    stNicolas: "St nicolas",
    blackForest: "Forêt noire",
    variousMovement: "Mouvements divers",
  },
  en: {
    allArticle: "All items",
    comtoise: "Comtoises",
    lanterne: "Lanterns",
    stNicolas: "St nicolas",
    blackForest: "Black forests",
    variousMovement: "Various movement",
  },
  de: {
    allArticle: "Alle Elemente",
    comtoise: "Comtoises",
    lanterne: "Laternen",
    stNicolas: "St nicolas",
    blackForest: "Schwarze Wälder",
    variousMovement: "Verschiedene Bewegungen",
  },
};

const workshopTrad = {
  fr: {
    allArticle: "Tous les articles",
    tool: "Outils",
    restoration: "Restauration",
    plug: "Bouchonner",
    pivot: "Planter un pivot",
    toClean: "Nettoyer",
  },
  en: {
    allArticle: "All items",
    tool: "Tools",
    restoration: "Restoration",
    plug: "Plug",
    pivot: "Plant a pivot",
    toClean: "To clean",
  },
  de: {
    allArticle: "Alle Elemente",
    tool: "Aussen",
    restoration: "Restauration",
    plug: "Stecker",
    pivot: "Pflanzen Sie einen Drehpunkt",
    toClean: "Reinigen",
  },
};

const elecTrad = {
  fr: {
    allArticle: "Tous les articles",
    historic: "Historique",
    electromagnetism: "L'électromagnétisme",
    ato: "Ato",
    brillie: "Brillié",
    bulleClock: "Bulle Clock",
  },
  en: {
    allArticle: "All items",
    historic: "Historic",
    electromagnetism: "Electromagnetism",
    ato: "Ato",
    brillie: "Brillié",
    bulleClock: "Bulle Clock",
  },
  de: {
    allArticle: "Alle Elemente",
    historic: "Historisch",
    electromagnetism: "Elektromagnetismus",
    ato: "Ato",
    brillie: "Brillié",
    bulleClock: "Bulle Clock",
  },
};

const traduction = {
  menuTrad: menuTrad.fr,
  workshopTrad: workshopTrad.fr,
  elecTrad: elecTrad.fr,
  mecaTrad: mecaTrad.fr,
};

export { traduction };
